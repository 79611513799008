import {
  Box,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  useEffect,
  useRef,
} from 'react';
import { marked } from 'marked';

export default function MessageList(props) {
  const {
    items,
    loading,
  } = props;
  const scrollTargetRef = useRef();

  useEffect(() => {
    if (items.length > 0 || loading) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [
    loading,
    items.length,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      pt={2}
      px={2}
      sx={{
        height: '100%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
      }}
    >
      {items.map((item) => (
        <MessageItem
          key={item.id}
          date={item.attributes.createdAt}
          message={item.attributes.content}
          role={item.attributes.role}
        />
      ))}
      {loading && <MessageLoadingIndicator />}
      <div ref={scrollTargetRef} id="scroll-target" />
    </Box>
  );
}

function MessageItem(props) {
  const {
    role,
    date,
    message,
  } = props;

  const flexAlign = role === 'user' ? 'flex-end' : 'flex-start';
  const dateDisplay = DateTime
    .fromISO(date)
    .setLocale('de')
    .toLocaleString(DateTime.DATETIME_SHORT);

  const markedMessage = marked(message, {
    smartypants: true,
    breaks: true,
    renderer: CUSTOM_RENDERER,
  });

  return (
    <Box
      alignSelf={flexAlign}
      sx={MESSAGE_BOX_STYLES}
    >
      <Paper
        sx={role === 'user' ? MESSAGE_BOX_STYLES_USER : MESSAGE_BOX_STYLES_ASSISTANT}
      >
        <Typography
          color={role === 'user' ? 'primary.contrastText' : 'text.primary'}
          component="div"
          dangerouslySetInnerHTML={{ __html: markedMessage }}
          sx={{
            wordBreak: 'break-word',
            '& p': {
              my: 0,
            },
          }}
          variant="body2"
        />
      </Paper>
      <Box
        display="flex"
        justifyContent={flexAlign}
        mt={0.5}
        px={2}
      >
        <Typography
          color="text.disabled"
          component="div"
          variant="caption"
        >
          {dateDisplay}
        </Typography>
      </Box>
    </Box>
  );
}

function MessageLoadingIndicator() {
  return (
    <Box sx={MESSAGE_BOX_STYLES}>
      <Skeleton
        height={52}
        variant="rounded"
      />
    </Box>
  );
}

const CUSTOM_RENDERER = new marked.Renderer();

const MESSAGE_BOX_STYLES = {
  maxWidth: { xs: '90%', md: '80%' },
  mb: 2,
};

const MESSAGE_BOX_STYLES_ASSISTANT = {
  p: 2,
  bgcolor: 'grey.200',
  borderBottomLeftRadius: 0,
};

const MESSAGE_BOX_STYLES_USER = {
  p: 2,
  bgcolor: 'primary.main',
  borderBottomRightRadius: 0,
};
